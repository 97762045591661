.wrapper {
  width: 100%;
  height: 100%;
  border-left: 10% solid $color-white;
  margin-top: 30px;

  &:before {
    content: '';
    position: absolute;
    background: $color-white;
    left: 0;
    right: 0;
    height: 100%;
    width: 10%;
  }
}

.navigation {
  @include flexbox(column, wrap, flex-start, flex-start);

  @include sm-min(sm) {
    @include flexbox(row, wrap, flex-start, flex-start);
  }

  &__item,
  &__link {
    @include font-size($font-settings-body);
    @include font($type-face: $font-sans-serif);
  }

  &__item {
    background-color: transparent;
    border: none;
    cursor: $cursorFabi;
    margin-right: 15px;
    letter-spacing: 0.06rem;
    padding: 7px 0px 2px;

    @include sm-min {
      padding: 1px 7px 2px;
    }

    a {
      color: $color-black;
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active,
    .active {
      .navigation__link:after,
      .navigation__text:after {
        animation-name: animate-menu-dotty;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    &:first-child {
      padding-left: 0;
      padding-top: 0px;

      @include sm-min {
        padding-top: 1px;
      }
    }
  }

  &__link,
  &__text {
    &:after {
      content: '';
      width: 97%;
      height: 1px;
      display: block;
      background-position-x: 0;
      position: absolute;
      background-repeat: repeat-x;
      bottom: -1px;
      left: 3%;
      background-image: url(../svg/menu-dotty.min.svg);
    }
  }
}

.anchor {
  cursor: $cursorFabi;
  color: $color-black;
  text-decoration: none;
  letter-spacing: 0.06rem;

  &__link,
  &__text {
    &:after {
      content: '';
      width: 99%;
      height: 1px;
      display: block;
      background-position-x: 0;
      position: absolute;
      background-repeat: repeat-x;
      bottom: -1px;
      left: 0.5%;
      background-image: url(../svg/menu-dotty.min.svg);
    }
  }

  &:hover,
  &:focus,
  &:active,
  .active {
    .anchor__link:after,
    .anchor__text:after {
      animation-name: animate-menu-dotty;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

.text {
  .title-h2 + & {
    margin-top: 30px;
  }

  margin-top: 60px;
  margin-bottom: 50px;

  &__item {
    margin-bottom: 20px;
    letter-spacing: 0.06rem;
  }

  @include xs-max {
    margin-top: 50px;
  }

  &__item--first {
    margin-bottom: 0;
  }

  &__item--underlien {
    .text__txt {
      &:after {
        content: '';
        width: 99%;
        height: 1px;
        display: block;
        background-position-x: 0;
        position: absolute;
        background-repeat: repeat-x;
        bottom: -1px;
        left: 1%;
        background-image: url(../svg/menu-dotty.min.svg);
      }
    }
  }
}

.nowhitespace {
  @include flexbox(column, wrap, flex-start, flex-start);

  white-space: pre-line;

  @include sm-min {
    white-space: nowrap;
    display: inline;
  }
}

.text-wrapper {
  width: 50%;
  max-width: 700px;

  @include md-max {
    width: 100%;
  }
}

.title-h1 {
  @include font($type-face: $font-title);
  @include font-size($font-settings-h1);

  margin-top: 30px;
  letter-spacing: 0.07rem;
}

.image-wrapper {
  max-width: 57rem;
  margin-top: 35px;
}

.title-h2 {
  @include font($font-sans-serif, $font-weight-bold);
  @include font-size($font-settings-body);

  letter-spacing: 0.06rem;
}

.popup {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  transition: opacity 1s linear;

  &__inner {
    padding-top: 50px;
    transform: translateY(-300px);
    transition: transform 0.2s linear 0.2s;

    .showContact--animated & {
      transition: transform 0.2s linear;
      transform: translateY(0);
    }
  }

  .showContact & {
    @include flexbox(row, wrap, flex-start, flex-start);

    background-color: $color-white;
  }

  .showContact--animated & {
    opacity: 1;
    transition: all 0.2s linear;
  }
}

.close-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  cursor: $cursorFabi;

  &__line {
    height: 1px;
    width: 100%;
    background: $color-black;
  }

  &__line1 {
    transform: rotate(45deg);
  }

  &__line2 {
    transform: rotate(-45deg);
  }
}

.desktop-only {
  display: none;

  @include sm-min(sm) {
    display: inline;
  }
}

@keyframes animate-menu-dotty {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 5px;
  }
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
