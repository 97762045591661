@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
*, *:before, *:after {
  box-sizing: border-box;
  position: relative; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
  Gets rid of the annoying outline for mouse users but preserves it for keyboard users, and is ignored by browsers that don’t support :focus-visible.
  @Link: https://twitter.com/LeaVerou/status/1045768279753666562
 */
:focus:not(:focus-visible) {
  outline: none; }

.sr {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap; }

.no-tab *:focus {
  outline: 0;
  box-shadow: none; }

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

/*
  Usage:
  @extend %clearfix;
*/
/*
  Usage:
  @include vertical-align-middle(position, width);
*/
/*
  Usage:
  @include vertical-align(position);
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xl-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include sm-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include md-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include lg-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xl-min($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xs-max($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include sm-max($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include md-max($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include lg-max($print) {

  }
*/
/*
  Usage:
  if $print has no value, @media print won't be used
  if $print has true, @media print will be used
  @include xl-max($print) {

  }
*/
/*
  Usage:
  @include flexbox(direction, wrap, justify-content, align-content);
*/
/*
  Usage:
  @include font(type-face, font-weight, font-style);
*/
/*
  Usage:
  @include font-size(font-settings-variable);
*/
/*
  HELPER MIXINS (do not use)
*/
/*
  Usage:
  Livingdocs component margin-bottom
*/
/*
  Usage:
  @include responsive-ratio(width%, ratiowidth, ratioheight, 'childelement as string');
*/
/*
  Usage:
  @include size(width unit, height unit);
*/
html {
  font-size: 62.5%; }

body:before,
body:after {
  content: '';
  position: fixed;
  background: #ffffff;
  left: 0;
  right: 0;
  height: 30px; }

body:before {
  top: 0; }

body:after {
  bottom: 0; }

body {
  font-family: "Cantarell", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem;
  line-height: 2.2rem;
  height: 100%;
  position: relative;
  border-left: 30px solid #ffffff;
  border-right: 30px solid #ffffff; }
  @media screen and (min-width: 600px) {
    body {
      font-size: 1.6rem;
      line-height: 2.2rem; } }
  @media print {
    body {
      font-size: 9pt;
      line-height: 11pt; } }
  body:not(.no-focus) *:focus {
    outline: #f9f5e3 auto 1px; }

*,
*:before,
*:after {
  box-sizing: border-box; }

h1 {
  font-size: 3.5rem;
  line-height: 1.2; }
  @media screen and (min-width: 992px) {
    h1 {
      font-size: 3.5rem;
      line-height: 1.2; } }
  @media print {
    h1 {
      font-size: 30pt;
      line-height: 30pt; } }

img {
  max-width: 100%;
  height: auto;
  display: block; }
  img.lazy {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease; }
  img.loaded {
    opacity: 1;
    visibility: visible; }

.ns-bold,
strong,
b {
  font-weight: 700; }

a {
  cursor: url("./../svg/F_Rodel_Hurray.png"), pointer; }

.superscript,
.subscript,
sup,
sub {
  font-size: 60%;
  line-height: 0;
  vertical-align: baseline;
  position: relative;
  padding: 0 1px; }

.superscript,
sup {
  top: -0.6em; }

sub,
.subscript {
  top: 0.4em; }

.no-focus *:focus {
  outline: 0; }

.responsive-embed {
  position: relative;
  width: 100%;
  height: 0;
  padding: 56.25% 0 0 0;
  display: block; }
  .responsive-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }

.wrapper {
  width: 100%;
  height: 100%;
  border-left: 10% solid #ffffff;
  margin-top: 30px; }
  .wrapper:before {
    content: '';
    position: absolute;
    background: #ffffff;
    left: 0;
    right: 0;
    height: 100%;
    width: 10%; }

.navigation {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start; }
  @media (min-width: 768px) {
    .navigation {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start; } }
  .navigation__item, .navigation__link {
    font-size: 1.5rem;
    line-height: 2.2rem;
    font-family: "Cantarell", sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    @media screen and (min-width: 600px) {
      .navigation__item, .navigation__link {
        font-size: 1.6rem;
        line-height: 2.2rem; } }
    @media print {
      .navigation__item, .navigation__link {
        font-size: 9pt;
        line-height: 11pt; } }
  .navigation__item {
    background-color: transparent;
    border: none;
    cursor: url("./../svg/F_Rodel_Hurray.png"), pointer;
    margin-right: 15px;
    letter-spacing: 0.06rem;
    padding: 7px 0px 2px; }
    @media (min-width: 768px) {
      .navigation__item {
        padding: 1px 7px 2px; } }
    .navigation__item a {
      color: #000000;
      text-decoration: none; }
    .navigation__item:hover .navigation__link:after,
    .navigation__item:hover .navigation__text:after, .navigation__item:focus .navigation__link:after,
    .navigation__item:focus .navigation__text:after, .navigation__item:active .navigation__link:after,
    .navigation__item:active .navigation__text:after,
    .navigation__item .active .navigation__link:after,
    .navigation__item .active .navigation__text:after {
      animation-name: animate-menu-dotty;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; }
    .navigation__item:first-child {
      padding-left: 0;
      padding-top: 0px; }
      @media (min-width: 768px) {
        .navigation__item:first-child {
          padding-top: 1px; } }
  .navigation__link:after, .navigation__text:after {
    content: '';
    width: 97%;
    height: 1px;
    display: block;
    background-position-x: 0;
    position: absolute;
    background-repeat: repeat-x;
    bottom: -1px;
    left: 3%;
    background-image: url(../svg/menu-dotty.min.svg); }

.anchor {
  cursor: url("./../svg/F_Rodel_Hurray.png"), pointer;
  color: #000000;
  text-decoration: none;
  letter-spacing: 0.06rem; }
  .anchor__link:after, .anchor__text:after {
    content: '';
    width: 99%;
    height: 1px;
    display: block;
    background-position-x: 0;
    position: absolute;
    background-repeat: repeat-x;
    bottom: -1px;
    left: 0.5%;
    background-image: url(../svg/menu-dotty.min.svg); }
  .anchor:hover .anchor__link:after,
  .anchor:hover .anchor__text:after, .anchor:focus .anchor__link:after,
  .anchor:focus .anchor__text:after, .anchor:active .anchor__link:after,
  .anchor:active .anchor__text:after,
  .anchor .active .anchor__link:after,
  .anchor .active .anchor__text:after {
    animation-name: animate-menu-dotty;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear; }

.text {
  margin-top: 60px;
  margin-bottom: 50px; }
  .title-h2 + .text {
    margin-top: 30px; }
  .text__item {
    margin-bottom: 20px;
    letter-spacing: 0.06rem; }
  @media (max-width: 599px) {
    .text {
      margin-top: 50px; } }
  .text__item--first {
    margin-bottom: 0; }
  .text__item--underlien .text__txt:after {
    content: '';
    width: 99%;
    height: 1px;
    display: block;
    background-position-x: 0;
    position: absolute;
    background-repeat: repeat-x;
    bottom: -1px;
    left: 1%;
    background-image: url(../svg/menu-dotty.min.svg); }

.nowhitespace {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: pre-line; }
  @media (min-width: 768px) {
    .nowhitespace {
      white-space: nowrap;
      display: inline; } }

.text-wrapper {
  width: 50%;
  max-width: 700px; }
  @media (max-width: 991px) {
    .text-wrapper {
      width: 100%; } }

.title-h1 {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 3.5rem;
  line-height: 1.2;
  margin-top: 30px;
  letter-spacing: 0.07rem; }
  @media screen and (min-width: 992px) {
    .title-h1 {
      font-size: 3.5rem;
      line-height: 1.2; } }
  @media print {
    .title-h1 {
      font-size: 30pt;
      line-height: 30pt; } }

.image-wrapper {
  max-width: 57rem;
  margin-top: 35px; }

.title-h2 {
  font-family: "Cantarell", sans-serif;
  font-weight: 700;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem;
  line-height: 2.2rem;
  letter-spacing: 0.06rem; }
  @media screen and (min-width: 600px) {
    .title-h2 {
      font-size: 1.6rem;
      line-height: 2.2rem; } }
  @media print {
    .title-h2 {
      font-size: 9pt;
      line-height: 11pt; } }

.popup {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  transition: opacity 1s linear; }
  .popup__inner {
    padding-top: 50px;
    transform: translateY(-300px);
    transition: transform 0.2s linear 0.2s; }
    .showContact--animated .popup__inner {
      transition: transform 0.2s linear;
      transform: translateY(0); }
  .showContact .popup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff; }
  .showContact--animated .popup {
    opacity: 1;
    transition: all 0.2s linear; }

.close-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  cursor: url("./../svg/F_Rodel_Hurray.png"), pointer; }
  .close-btn__line {
    height: 1px;
    width: 100%;
    background: #000000; }
  .close-btn__line1 {
    transform: rotate(45deg); }
  .close-btn__line2 {
    transform: rotate(-45deg); }

.desktop-only {
  display: none; }
  @media (min-width: 768px) {
    .desktop-only {
      display: inline; } }

@keyframes animate-menu-dotty {
  from {
    background-position-x: 0; }
  to {
    background-position-x: 5px; } }

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
